import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  computed: {
    ...mapGetters({
      searchQuery: 'GET_SABRE_FLIGHT_SEARCH_QUERY',
      changingItem: 'GET_SABRE_CHANGING_FLIGHT_ITEM',
      destinationList: 'GET_DESTINATION_IMAGES',
      filteredItems: 'GET_SABRE_FLIGHT_FILTERED_ITEMS',
    }),
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    arrivalCityCode() {
      const { changingItem } = this;
      return changingItem?.legs[0].excerpt.arrival.city;
    },
    arrivalCityName() {
      const { destinations, arrivalCityCode } = this;
      return destinations?.find((d) => d.code === arrivalCityCode)?.name_Loc?.content || '';
    },
    departureDayjsObj() {
      const { changingItem } = this;
      const oTime = changingItem?.legs[0].excerpt?.departure.time || changingItem?.legs[0].departure.time;
      return dayjs(new Date(`${changingItem?.legs[0].departureDate} ${oTime}`));
    },
    departureDate() {
      const { departureDayjsObj } = this;
      return dayjs(departureDayjsObj).format('DD/MM');
    },
    departureTime() {
      const { changingItem } = this;
      const oTime = changingItem?.legs[0].excerpt?.departure.time || changingItem?.legs[0].departure.time;
      return oTime.substr(0, 5);
      // const { departureDayjsObj } = this;
      // return dayjs(departureDayjsObj).format('HH:mm');
    },
    departureDayName() {
      const { departureDayjsObj } = this;
      const weekNameArray = Object.values(this.$t('weekName'));
      return weekNameArray[dayjs(departureDayjsObj).day()];
    },
    arrivalOriginDate() {
      const { changingItem } = this;
      const dateAdjustment = changingItem?.legs[1].excerpt?.arrival?.dateAdjustment || changingItem?.legs[1].arrival?.dateAdjustment || 0;
      return dayjs(changingItem?.legs[1].departureDate).add(dateAdjustment, 'day').format('YYYY-MM-DD');
    },
    arrivalDayjsObj() {
      const { changingItem, arrivalOriginDate } = this;
      const oTime = changingItem?.legs[1].excerpt?.arrival.time || changingItem?.legs[1].arrival.time;
      return dayjs(new Date(`${arrivalOriginDate} ${oTime}`));
    },
    arrivalDate() {
      const { arrivalDayjsObj } = this;
      return dayjs(arrivalDayjsObj).format('DD/MM');
    },
    arrivalDayName() {
      const { arrivalDayjsObj } = this;
      const weekNameArray = Object.values(this.$t('weekName'));
      return weekNameArray[dayjs(arrivalDayjsObj).day()];
    },
  },
  methods: {
    getDestCityName(cityCode) {
      const { destinations } = this;
      const one = destinations.find((d) => d.code === cityCode);
      return one?.name || one?.name_Loc?.content || cityCode;
    },
    getElapsedTime(time) {
      return { hour: Math.floor(time / 60), minute: time % 60 };
    },
    gotoSearchPage() {
      this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', []);
      this.$router.push({ path: `/sabre/fo-search-result/?${this.searchQuery}` });
    },
  },
};
