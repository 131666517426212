<template>
  <section class="directflightsonly_area">
    <div class="container px-5">
      <a href="#" class="btn-return" @click="() => gotoSearchPage()">
        <span><i class="fa-solid fa-chevron-right"></i></span>
        {{$t("sabre.change-flight.return-back-point")}}
      </a>
      <div class="changeflights_title">
        <h2><span><img src="/assets/sabre-img/airplane.png" alt="airplane"></span>
          {{ $t("sabre.change-flight.where-flight-back", {dest: arrivalCityName, day: departureDate, time: departureTime, dayName: departureDayName})}}
        </h2>
      </div>
      <div class="directflightsonly_innerbox searchresultbox" v-if="changingItem">

        <one-flight-item kind="changeFlight" :data="changingItem.legs[0]" />

        <!---------- Details collapse start---------------->
        <div class="collapse flightdetailscollapse" id="directflight">
          <div class="flightdetails_innerbox">
            <h4>{{$t("sabre.search-result.box.flight-details")}}</h4>

            <div class="flightdetails_item">

              <div v-for="(segment, inx) in changingItem.legs[0].segmentExcerpts" :key="inx">
                <collapse-one-item :data="segment" :department="changingItem.legs[0].excerpt.cabinCode" :checkinBaggage="changingItem.legs[0].excerpt.checkinBaggageAllowance"/>
                <h3 v-if="inx !== changingItem.legs[0].excerpt.connections">
                  <strong>{{$t("sabre.search-result.box.stop-in", {dest: getDestCityName(segment.arrival.city)})}}</strong>
                  {{$t("sabre.search-result.box.flight-hour-minute", getElapsedTime(segment.elapsedTime))}}
                </h3>
              </div>

            </div>
          </div>
        </div>
        <!---------- Details collapse End------------------>

      </div>
      <div class="changeflights_titlebottom">
        <h2><span><img src="/assets/sabre-img/airplane1.png" alt="airplane1"></span>
          {{$t("sabre.change-flight.look-for-flight-from-where", {where: arrivalCityName, day: arrivalDate, dayName: arrivalDayName})}}
        </h2>
        <small>{{$t("sabre.change-flight.note-message")}}</small>
      </div>
    </div>
  </section>
</template>

<script>

import changeFlightTopItem from './returnFlightChangeMixin';

export default {
  name: 'change-return-flight-desktop',
  mixins: [changeFlightTopItem],
  components: {
    OneFlightItem: () => import('../searchResultBox/searchResultBoxAtom/oneFlightItem'),
    CollapseOneItem: () => import('../searchResultBox/searchResultBoxAtom/collapseOneItem'),
  },
};
</script>
